/*global $*/
/*eslint no-undef: "error"*/

export default class VideoImg {
    classBlock = 'video-img--block'
    classButton = 'video-img--button'
    classPlayer = 'video-img--player'

    constructor () {
        let self = this

        $(`.${self.classButton}`).click(function () {
            let video = $(this).data('video')

            $(this).fadeOut()
            $(this).closest(`.${self.classBlock}`).find(`.${self.classPlayer}`).html(`<iframe src="https://www.youtube.com/embed/${video}?feature=oembed&autoplay=1" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`)
        })
    }
}
