<template lang="pug">
.form(:class="'form-' + formType")
  div(v-show="!sendForm")
    .title.title-center(v-if="(formType === 'type1') || formType === 'type2'" v-html="formTitle")
    p.center(v-if="(formType === 'type1') || formType === 'type2'" v-html="formText")

    .form__fields
      .input-text
        input.input-text__input(
          type="text"
          v-model="fieldName"
          :placeholder="formFieldNamePlaceholder"
        )

      .input-text
        input.input-text__input(
          type="tel"
          v-model="fieldPhone"
          :placeholder="formFieldPhonePlaceholder"
          v-maska="['+7 (###) ##-##-##', '+7 (###) ###-##-##']"
        )

      .input-text(v-if="formType === 'type2'")
        input.input-text__input(
          type="email"
          v-model="fieldEmail"
          :placeholder="formFieldEmailPlaceholder"
        )

      .input-textarea(v-if="formType === 'type2'")
        textarea.input-textarea__input(
          v-model="fieldText"
          :placeholder="formFieldTextPlaceholder"
        )

    .form__consent
      label.consent.consent-center-1024
        input.consent__input(
          type="checkbox"
          value="consent"
          checked="checked"
          v-model="consent"
        )
        span.consent__button
        span.consent__label(v-html="formConsentText")

    .form__button
      .button(
        :class="{'button-disable': v$.$invalid}"
        @click="getSend()"
      )
        .button__text(v-html="formButtonName")
        .button__button
          svg.button__icon
            use(xlink:href="#icon-arrow")
  div(v-show="sendForm")
    .title.title-center(v-html="formSuccessTitle")
    p.center(v-html="formSuccessText")

</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required, minLength, maxLength } from '@vuelidate/validators'
import { mapActions } from 'vuex'
export default {
  name: 'AppForm',

  setup () {
    return { v$: useVuelidate() }
  },

  props: [
    "formSettingTitle",
    "formSettingSubject",

    "formButtonName",

    "formFieldName",
    "formFieldNamePlaceholder",
    "formFieldPhone",
    "formFieldPhonePlaceholder",
    "formFieldEmail",
    "formFieldEmailPlaceholder",
    "formFieldText",
    "formFieldTextPlaceholder",
    "formFieldPage",

    "formSuccessTitle",
    "formSuccessText",

    "formConsentText",

    "formTitle",
    "formText",

    "formPageId",
    "formPagePagetitle",

    "formType",

    "formRedirect"
  ],

  data() {
    return {
      fieldName: '',
      fieldPhone: '',
      fieldEmail: '',
      fieldText: '',
      files: '',
      consent: ['consent'],
      sendForm: false
    }
  },

  methods: {
    ...mapActions([
      'Send'
    ]),

    getSend () {
      if (!this.v$.$invalid) {
        this.sendForm = !this.sendForm
        this.Send({
          setting: {
            title: this.formSettingTitle,
            subject: this.formSettingSubject
          },

          fields: [
            {
              title: this.formFieldName,
              value: this.fieldName
            },
            {
              title: this.formFieldPhone,
              value: this.fieldPhone
            },
            {
              title: this.formFieldEmail,
              value: this.fieldEmail
            },
            {
              title: this.formFieldText,
              value: this.fieldText
            },
            {
              title: this.formFieldPage,
              value: this.formPagePagetitle + ' (' + this.formPageId + ')'
            },
          ],
          files: this.files
        })

        this.fieldName = this.fieldPhone = this.fieldEmail = this.fieldText = ''

        // window.location.href = this.formRedirect

        setTimeout(() => {
          this.sendForm = !this.sendForm
          window.location.href = this.formRedirect
        }, 1500)
      }
    }
  },

  validations () {
    return {
      fieldPhone: {
        required,
        minLength: minLength(18),
        maxLength: maxLength(18)
      },

      consent: {
        required
      }
    }
  }
}
</script>
