/*global $*/
/*eslint no-undef: "error"*/

export default class ButtonTop {
    classButton = 'button-top--button'

    constructor () {
        let self = this
        let objButton = $(`.${self.classButton}`)

        $(window).scroll(function () {
            if ($(this).scrollTop() > 100) {
                objButton.fadeIn()
            } else {
                objButton.fadeOut()
            }
        })

        objButton.click(function () {
            $(`html`).animate({ scrollTop: 0 }, 600)
            return false
        })
    }
}
