import Swiper, {Autoplay, EffectCoverflow, Navigation, Pagination, Thumbs} from 'swiper'
Swiper.use([Navigation, Pagination, EffectCoverflow, Autoplay, Thumbs])

export default class SwiperSlider {
    createSert () {
        return new Swiper('#slider--sert', {
            /*
            autoplay: {
                delay: 7500,
            },
            */
            slidesPerView: 5,
            spaceBetween: 40,
            navigation: {
                nextEl: '.slider--sert-next',
                prevEl: '.slider--sert-prev',
            },
            pagination: {
                el: '.swiper-pagination--sert',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 2,
                    spaceBetween: 20
                },
                320: {
                    slidesPerView: 2,
                    spaceBetween: 20
                },
                360: {
                    slidesPerView: 2,
                    spaceBetween: 20
                },
                375: {
                    slidesPerView: 2,
                    spaceBetween: 20
                },
                414: {
                    slidesPerView: 2,
                    spaceBetween: 20
                },
                480: {
                    slidesPerView: 3,
                    spaceBetween: 20
                },
                514: {
                    slidesPerView: 3,
                    spaceBetween: 20
                },
                640: {
                    slidesPerView: 3,
                    spaceBetween: 20
                },
                768: {
                    slidesPerView: 4,
                    spaceBetween: 20
                },
                900: {
                    slidesPerView: 5,
                    spaceBetween: 20
                },
                991: {
                    slidesPerView: 5,
                    spaceBetween: 20
                },
                1024: {
                    slidesPerView: 5,
                    spaceBetween: 20
                },
                1240: {
                    slidesPerView: 5,
                    spaceBetween: 40
                }
            }
        })
    }

    createReview () {
        return new Swiper('#slider--review', {
            /*
            autoplay: {
                delay: 7500,
            },
            */
            slidesPerView: 3,
            spaceBetween: 20,
            navigation: {
                nextEl: '.slider--review-next',
                prevEl: '.slider--review-prev',
            },
            pagination: {
                el: '.swiper-pagination--review',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 1
                },
                320: {
                    slidesPerView: 1
                },
                360: {
                    slidesPerView: 1
                },
                375: {
                    slidesPerView: 1
                },
                414: {
                    slidesPerView: 1
                },
                480: {
                    slidesPerView: 1
                },
                514: {
                    slidesPerView: 2
                },
                640: {
                    slidesPerView: 2
                },
                768: {
                    slidesPerView: 2
                },
                900: {
                    slidesPerView: 2
                },
                991: {
                    slidesPerView: 2
                },
                1024: {
                    slidesPerView: 3
                },
                1240: {
                    slidesPerView: 3
                }
            }
        })
    }

    createVideo () {
        return new Swiper('#slider--video', {
            /*
            autoplay: {
                delay: 7500,
            },
            */
            slidesPerView: 3,
            spaceBetween: 20,
            navigation: {
                nextEl: '.slider--video-next',
                prevEl: '.slider--video-prev',
            },
            pagination: {
                el: '.swiper-pagination--video',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 1
                },
                320: {
                    slidesPerView: 1
                },
                360: {
                    slidesPerView: 1
                },
                375: {
                    slidesPerView: 1
                },
                414: {
                    slidesPerView: 1
                },
                480: {
                    slidesPerView: 1
                },
                514: {
                    slidesPerView: 2
                },
                640: {
                    slidesPerView: 2
                },
                768: {
                    slidesPerView: 2
                },
                900: {
                    slidesPerView: 2
                },
                991: {
                    slidesPerView: 2
                },
                1024: {
                    slidesPerView: 3
                },
                1240: {
                    slidesPerView: 3
                }
            }
        })
    }

    createGallery () {
        return new Swiper('#slider--gallery', {
            /*
            autoplay: {
                delay: 7500,
            },
            */
            slidesPerView: 4,
            slidesPerGroup: 4,
            spaceBetween: 20,
            navigation: {
                nextEl: '.slider--gallery-next',
                prevEl: '.slider--gallery-prev',
            },
            pagination: {
                el: '.swiper-pagination--gallery',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 2,
                    slidesPerGroup: 2
                },
                320: {
                    slidesPerView: 2,
                    slidesPerGroup: 2
                },
                360: {
                    slidesPerView: 2,
                    slidesPerGroup: 2
                },
                375: {
                    slidesPerView: 2,
                    slidesPerGroup: 2
                },
                414: {
                    slidesPerView: 2,
                    slidesPerGroup: 2
                },
                480: {
                    slidesPerView: 2,
                    slidesPerGroup: 2
                },
                514: {
                    slidesPerView: 3,
                    slidesPerGroup: 3
                },
                640: {
                    slidesPerView: 3,
                    slidesPerGroup: 3
                },
                768: {
                    slidesPerView: 3,
                    slidesPerGroup: 3
                },
                900: {
                    slidesPerView: 3,
                    slidesPerGroup: 3
                },
                991: {
                    slidesPerView: 4,
                    slidesPerGroup: 4
                },
                1024: {
                    slidesPerView: 4,
                    slidesPerGroup: 4
                },
                1240: {
                    slidesPerView: 4,
                    slidesPerGroup: 4
                }
            }
        })
    }

    createGallery2 () {
        return new Swiper('#slider--gallery2', {
            /*
            autoplay: {
                delay: 7500,
            },
            */
            slidesPerView: 4,
            slidesPerGroup: 4,
            spaceBetween: 20,
            navigation: {
                nextEl: '.slider--gallery2-next',
                prevEl: '.slider--gallery2-prev',
            },
            pagination: {
                el: '.swiper-pagination--gallery2',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 2,
                    slidesPerGroup: 2
                },
                320: {
                    slidesPerView: 2,
                    slidesPerGroup: 2
                },
                360: {
                    slidesPerView: 2,
                    slidesPerGroup: 2
                },
                375: {
                    slidesPerView: 2,
                    slidesPerGroup: 2
                },
                414: {
                    slidesPerView: 2,
                    slidesPerGroup: 2
                },
                480: {
                    slidesPerView: 2,
                    slidesPerGroup: 2
                },
                514: {
                    slidesPerView: 3,
                    slidesPerGroup: 3
                },
                640: {
                    slidesPerView: 3,
                    slidesPerGroup: 3
                },
                768: {
                    slidesPerView: 3,
                    slidesPerGroup: 3
                },
                900: {
                    slidesPerView: 3,
                    slidesPerGroup: 3
                },
                991: {
                    slidesPerView: 4,
                    slidesPerGroup: 4
                },
                1024: {
                    slidesPerView: 4,
                    slidesPerGroup: 4
                },
                1240: {
                    slidesPerView: 4,
                    slidesPerGroup: 4
                }
            }
        })
    }

    createGallery3 () {
        return new Swiper('#slider--gallery3', {
            /*
            autoplay: {
                delay: 7500,
            },
            */
            slidesPerView: 4,
            slidesPerGroup: 4,
            spaceBetween: 20,
            navigation: {
                nextEl: '.slider--gallery3-next',
                prevEl: '.slider--gallery3-prev',
            },
            pagination: {
                el: '.swiper-pagination--gallery3',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 2,
                    slidesPerGroup: 2
                },
                320: {
                    slidesPerView: 2,
                    slidesPerGroup: 2
                },
                360: {
                    slidesPerView: 2,
                    slidesPerGroup: 2
                },
                375: {
                    slidesPerView: 2,
                    slidesPerGroup: 2
                },
                414: {
                    slidesPerView: 2,
                    slidesPerGroup: 2
                },
                480: {
                    slidesPerView: 2,
                    slidesPerGroup: 2
                },
                514: {
                    slidesPerView: 3,
                    slidesPerGroup: 3
                },
                640: {
                    slidesPerView: 3,
                    slidesPerGroup: 3
                },
                768: {
                    slidesPerView: 3,
                    slidesPerGroup: 3
                },
                900: {
                    slidesPerView: 3,
                    slidesPerGroup: 3
                },
                991: {
                    slidesPerView: 4,
                    slidesPerGroup: 4
                },
                1024: {
                    slidesPerView: 4,
                    slidesPerGroup: 4
                },
                1240: {
                    slidesPerView: 4,
                    slidesPerGroup: 4
                }
            }
        })
    }
    constructor () {
        if(document.getElementById('slider--sert') != null) {
            this.createSert()
        }

        if(document.getElementById('slider--review') != null) {
            this.createReview()
        }

        if(document.getElementById('slider--video') != null) {
            this.createVideo()
        }

        if(document.getElementById('slider--gallery') != null) {
            this.createGallery()
        }

        if(document.getElementById('slider--gallery2') != null) {
            this.createGallery2()
        }

        if(document.getElementById('slider--gallery3') != null) {
            this.createGallery3()
        }
    }
}
