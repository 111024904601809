/*global $*/
/*eslint no-undef: "error"*/
export default class HeaderFixed {
    classBlock = 'header--fixed'
    classBlockFixed = 'header-fixed'

    constructor () {
        let self = this
        let distanceTop = 200

        if ((window.pageYOffset >= distanceTop)) {
            $(`.${self.classBlock}`).addClass(self.classBlockFixed)
        } else {
            $(`.${self.classBlock}`).removeClass(self.classBlockFixed)
        }

        $(window).scroll(() => {
            let windowTop = window.pageYOffset

            if ((windowTop >= distanceTop)) {
                $(`.${self.classBlock}`).addClass(self.classBlockFixed)
            } else {
                $(`.${self.classBlock}`).removeClass(self.classBlockFixed)
            }
        })
    }
}
