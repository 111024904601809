/*global $*/
/*eslint no-undef: "error"*/
export default class ShowText {
    classBlock = 'show-text--block'
    classBlockOpen = 'show-text-open'
    classItem = 'show-text--item'
    classItemHidden = 'show-hidden'
    classButton = 'show-text--button'

    constructor () {
        let self = this

        $(`.${self.classButton}`).click(function () {
            $(this).closest(`.${self.classBlock}`).addClass(self.classBlockOpen)
            $(this).closest(`.${self.classBlock}`).find(`.${self.classItem}`).addClass(self.classItemHidden)
        })
    }
}
