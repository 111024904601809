/*global $*/
/*eslint no-undef: "error"*/
export default class Price {
    classButtons = 'price--buttons'
    classButton = 'price--button'
    classButtonActive = 'tag-active'

    classItems = 'price--items'
    classItem = 'price--item'
    classItemHidden = 'price-list__item-hidden-768'

    constructor () {
        let self = this

        $(`.${self.classButton}`).click(function () {
            let curBlock = $(this).data("block")

            $(this).closest(`.${self.classButtons}`).find(`.${self.classButton}`).removeClass(self.classButtonActive)
            $(this).addClass(self.classButtonActive)

            $(`.${self.classItems}`).find(`.${self.classItem}`).addClass(self.classItemHidden)
            $(`.${self.classItems}`).find(`.${self.classItem}[data-block='${curBlock}']`).removeClass(self.classItemHidden)
        })
    }
}
