/*global $*/
/*eslint no-undef: "error"*/

export default class ButtonBrand {
    classBlocks = 'button-brand--blocks'
    classBlock = 'button-brand--block'
    classHidden = 'product-brands__item-hidden'

    classButton = 'button-brand--button'

    classShow = 'button-brand--show'
    classShowHidden = 'show-hidden'

    constructor () {
        let self = this

        $(`.${self.classButton}`).click(function () {
            $(`.${self.classBlocks}`).find(`.${self.classBlock}`).removeClass(self.classHidden)
            $(`.${self.classShow}`).addClass(self.classShowHidden)
        })
    }
}
