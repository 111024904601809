/*global $*/
/*eslint no-undef: "error"*/
export default class HeaderMobile {
    classBlock = 'header-mobile--block'
    classButton = 'header-mobile--button'

    classBlockOpen = 'header-mobile-open'
    classBodyHidden = 'overflow-hidden-768'

    constructor () {
        let self = this

        $(`.${self.classButton}`).click(function () {
            $(`body`).toggleClass(self.classBodyHidden)
            $(`.${self.classBlock}`).toggleClass(self.classBlockOpen)
        })
    }
}
