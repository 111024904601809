/*eslint-disable no-unused-vars*/

import { createApp } from 'vue'
import Maska from 'maska'
import form from '@/store/form'
import './assets/js/css/ImportCss'
import { Fancybox } from '@fancyapps/ui'
import AppForm from '@/components/Form/AppForm.vue'
import SwiperSlider from '@/assets/js/Swiper/Swiper'
import Price from '@/assets/js/Price/Price'
import Faq from '@/assets/js/Faq/Faq'
import ButtonBrand from '@/assets/js/Button/Brand/ButtonBrand'
import ShowText from '@/assets/js/Show/Text/ShowText'
import HeaderMobile from '@/assets/js/Header/Mobile/HeaderMobile'
import MapIframe from '@/assets/js/Map/Iframe/MapIframe'
import VideoImg from '@/assets/js/Video/VideoImg/VideoImg'
import MenuMobile from '@/assets/js/Menu/Mobile/MenuMobile'
import ButtonTop from '@/assets/js/Button/Top/ButtonTop'
import HeaderFixed from '@/assets/js/Header/Fixed/HeaderFixed'

// Подключаем глобально jQuery
const $ = window.$ = window.jQuery = require('jquery')

document.addEventListener('DOMContentLoaded', () => {

    Fancybox.bind('[data-fancybox]', {
        mainClass: 'fancybox--popup'
    })

    if(document.getElementById('block--form-sale')!= null) {
        createApp({})
            .component('block-form', AppForm)
            .use(Maska)
            .use(form)
            .mount('#block--form-sale')
    }

    if(document.getElementById('block--form-consult')!= null) {
        createApp({})
            .component('block-form', AppForm)
            .use(Maska)
            .use(form)
            .mount('#block--form-consult')
    }

    if(document.getElementById('block--form-phone')!= null) {
        createApp({})
            .component('block-form', AppForm)
            .use(Maska)
            .use(form)
            .mount('#block--form-phone')
    }

    if(document.getElementById('block--form-order')!= null) {
        createApp({})
            .component('block-form', AppForm)
            .use(Maska)
            .use(form)
            .mount('#block--form-order')
    }

    if(document.getElementById('block--form-director')!= null) {
        createApp({})
            .component('block-form', AppForm)
            .use(Maska)
            .use(form)
            .mount('#block--form-director')
    }

    new SwiperSlider()
    new Price()
    new Faq()
    new ButtonBrand()
    new ShowText()
    new HeaderMobile()
    new MapIframe()
    new VideoImg()
    new MenuMobile()
    new ButtonTop()
    new HeaderFixed()
})
